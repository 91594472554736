.feedback-modal {
  height: 100%;
  overflow-y: auto;

  &.fade.show {
    background-color: rgba(0, 0, 0, 0.86);
  }
  .modal-content {
    background: hsl(0, 0%, 0%);
    border-radius: 40px;
    .modal-body {
      h1 {
        color: theme-color('white');
        font-style: normal;
        font-weight: bold;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }
      .under-title {
        font-weight: 500;
        color: rgba(255, 255, 255, 0.5);
      }
      .subtitle {
        font-weight: 500;
        color: #ffffff;
      }
      .rating-icon {
        display: inline-block;
        width: 60px;
        height: 60px;
        border-radius: 60px;
        background: #000000;
        border: 1px solid #323232;
        &.rating-active {
          background: #27AE60;
        }
      }
      .feedback-star-ratings {
          margin: 3rem 0 0 2rem;
          h5 {
            font-weight: 500;
            color: #ffffff;
            margin-bottom: 0;
          }
      }
    }
    .btn-skip {
        padding: 14px 28px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        opacity: 0.5;
        background: none;
        position: absolute;
        right: 0px;
        i {
            padding-left: 10px;
        }
        &:focus, &:active {
          box-shadow: none;
          outline: none;
        }
    }
  }
  .modal-dialog {
    .modal-content {
      .modal-body {
        padding-top: 40px;
        padding-bottom: 40px;
        .feedback-modal-icon {
          max-width: 200px;
          margin-bottom: 55px;
        }
        .feedback-modal-title {
          font-size: 30px;
        }
        .feedback-modal-actions {
          margin-top: 36px;

          button.feedback-modal-actions-btn {
            min-width: 140px;

            &.btn-primary {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}
