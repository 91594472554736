.dashboard-player {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dashboard-player-disabled {
  background-color: rgba(theme-color('black'), 0.9);
}

.dashboard-player-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 164px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: 1;
}

.dashboard-player .video-js-wrapper,
.dashboard-player .video-js {
  width: 100%;
  height: 100%;
}

.dashboard-player .vjs-remaining-time {
  display: none !important;
}

.dashboard-player .vjs-big-play-button {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: theme-color('primary');
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-color: theme-color('primary');
}

.dashboard-player .vjs-control-bar {
  height: 100px;
  z-index: 1000;
  top: 30px;
  height: 6em !important;
  width: 80% !important;
  margin: 0 auto;
}

.dashboard-player .vjs-play-progress {
  background-color: #fff;
}

.dashboard-player .video-js .vjs-load-progress div {
  background-color: rgba(255, 255, 255, 0.5);
}

.dashboard-player .vjs-icon-placeholder:before,
.vjs-icon-play:before {
  top: 15px;
  left: -50px;
}

.dashboard-player .timeline-toggler {
  position: absolute;
  top: 45px;
  right: 15px;
  z-index: 1001;
  button {
    background: none;
    border: none;
    outline: none;
    &:active,
    &:focus {
      outline: none;
      border: none;
    }
  }
}

.video-js .vjs-control-bar {
  background: none;
}

.dashboard-player .video-js .vjs-progress-control {
  margin-left: 40px;
  margin-right: 60px;
}

.dashboard-player .video-js .vjs-progress-control .vjs-progress-holder {
  background-color: rgba(255, 255, 255, 0.5);
}

.dashboard-player .video-js .vjs-play-control,
.dashboard-player .video-js .vjs-volume-panel,
.dashboard-player .video-js .vjs-fullscreen-control {
  display: none;
}

.dashboard-player .vjs-remaining-time .vjs-control-text {
  position: relative;
}

.dashboard-player .video-js .vjs-current-time {
  position: absolute;
  display: block;
  top: 0;
  right: 60px;
  width: 30px;
  line-height: 60px;
  padding: 0;
  font-size: 12px;
  display: none;
}

.dashboard-player .video-js .vjs-remaining-time {
  position: absolute;
  top: 0;
  left: 0;
  width: 10em;
}

.dashboard-player .video-js .vjs-remaining-time .vjs-control-text {
  position: absolute;
  bottom: 0;
  left: 15px;
  width: 100%;
  height: 30px;
  clip: auto;
  font-size: 10px;
  word-spacing: 9999px;
  text-align: left;
}

.dashboard-player .video-js .vjs-remaining-time .vjs-remaining-time-display {
  position: absolute;
  top: 7px;
  left: 15px;
  width: 100%;
  font-size: 20px;
  text-align: left;
}

.dashboard-player .video-js .vjs-custom-control-spacer {
  display: block;
  position: absolute;
  right: 0;
  width: 60px;
  height: 100%;
}

.dashboard-player .video-js .toggle-controls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 24px;
  height: 24px;
  margin: auto;
  font-size: 22px;
  padding: 0;
  outline: none;
}

.dashboard-player .toggle-controls-up {
  opacity: 1;
  position: absolute;
  right: 0;
  width: 60px;
  height: 60px;
  z-index: 100;
  font-size: 22px;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  color: rgba(255, 255, 255, 0.5);
  outline: none;
  z-index: 1001;
}

.dashboard-player .toggle-controls-up:hover {
  color: #fff;
}

.dashboard-player .show-controls .toggle-controls-up {
  opacity: 0;
}

.dashboard-player
  .video-js
  .vjs-remaining-time
  .vjs-remaining-time-display::first-letter {
  font-size: 0;
}

.dashboard-player .vjs-control-bar {
  opacity: 0 !important;
}

.dashboard-player .show-controls .vjs-control-bar {
  opacity: 1 !important;
  background: none;
}

.dashboard-player .video-js .vjs-play-progress:before {
  font-size: 16px;
  color: lighten(theme-color('primary'), 10%);
  background-color: theme-color('primary');
  border-radius: 4px solid theme-color('primary');
  border-radius: 50%;
}

.dashboard-player .video-js .vjs-progress-holder .vjs-load-progress,
.video-js .vjs-progress-holder .vjs-load-progress div,
.video-js .vjs-progress-holder .vjs-play-progress {
  background: theme-color('primary');
}

.dashboard-player .vjs-big-play-button {
  display: none !important;
}

.video-not-found-placeholder {
  color: theme-color('white');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-video-btn,
.pause-video-btn {
  width: 180px;
  height: 180px;
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  outline: none;
  transition: none;
  opacity: 0;
}

.display-play-btn .play-video-btn,
.display-pause-btn .pause-video-btn {
  opacity: 1;
  transition: none;
}

.display-play-btn.animate-play-btn .play-video-btn,
.display-pause-btn.animate-pause-btn .pause-video-btn {
  opacity: 0;
  width: 190px;
  height: 190px;
  transition: all 0.5s;
}

.play-pause-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
}

.dashboard-survey {
  z-index: 1001;
  position: absolute;
  top: 30px;
  right: 90px;
  width: 120px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  padding: 15px;
  color: theme-color('white');
  background: rgba(theme-color('white'), 0.1);
  border-radius: 30px;
  border: 0;
  outline: none !important;
}

.dashboard-back {
  z-index: 1001;
  position: absolute;
  top: 30px;
  left: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  padding: 15px 25px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  border: 0;
  outline: none !important;
  svg {
    margin-right: 5px;
  }
}

.dashboard-back:hover {
  color: #fff;
  text-decoration: none;
}

.dashboard-playlist {
  width: 308px;
  z-index: 1001;
  position: absolute;
  top: 60px;
  bottom: 60px;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  &.dashboard-playlist-narrow {
    width: 108px !important;
    transition: width 0.2s;
    transition-delay: 0.2s;
  }
  .playlist-item {
    padding: 1rem 1.5rem;
    background: rgba(0, 0, 0, 0.4);
    margin-bottom: 2rem;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .playlist-song {
    .song-icon {
      width: 46px;
      border-radius: 6px;
    }
    .song-details {
      padding-left: 0;
      h5 {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }
      p {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
        opacity: 0.5;
      }
    }
  }

  .playlist-monitor {
    .monitor-icon {
      span {
        display: block;
        width: 54px;
        height: 14px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 5px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        &.active:nth-child(3) {
          background: #f9ca3e;
        }
        &.active:nth-child(4) {
          background: #b4ca5c;
        }
        &.active:nth-child(5) {
          background: #4ec4aa;
        }
      }
    }
    .monitor-details {
      padding-left: 0;
      h5 {
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-transform: uppercase;
        color: #ffffff;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }
      strong {
        display: block;
        margin-bottom: 0;
        font-weight: 200;
        font-size: 64px;
        color: #ffffff;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }
      small {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        opacity: 0.5;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }
    }
    .content-hidden {
      .monitor-details {
        display: none;
      }
    }
  }

  .playlist-focus {
    .focus-icon {
      .btn-highlighted {
        .outer-circle {
          background: linear-gradient(
            270deg,
            #b465da 0%,
            #cf6cc9 28.04%,
            #ee609c 67.6%,
            #ee609c 100%
          ) !important;
        }
        .inner-circle {
          background: rgba(0, 0, 0, 0.6);
        }
      }
      .outer-circle {
        display: block;
        width: 46px;
        height: 46px;
        position: relative;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
      }
      .inner-circle {
        background: rgba(0, 0, 0, 0.4);
        top: 25%;
        left: 25%;
        width: 50%;
        height: 50%;
        position: relative;
        border-radius: 50%;
      }
    }
    .focus-details {
      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.dashboard-stats {
  position: absolute;
  width: 100%;
  height: 15%;
  left: 0%;
  right: 0%;
  bottom: 0px;
  padding: 40px 80px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  z-index: 1001;
}

.dashboard-leaderboard {
  width: 426px;
  z-index: 1001;
  position: absolute;
  top: 60px;
  bottom: 60px;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.dashboard-leaderboard * {
  color: #fff;
}

.dashboard-leaderboard-row {
  display: flex;
  height: 74px;
  background: rgba(0, 0, 0, 0.4);
  position: relative;
  padding: 15px;
  cursor: pointer;
  &:first-child {
    background: rgba(0, 0, 0, 0.8);
  }
  &:last-child {
    border-bottom-right-radius: 14px;
    border-bottom: none;
    &:after {
      border: none;
      background-color: transparent;
    }
  }
}

.leaderboard-title-row {
  text-transform: uppercase;
  justify-content: space-between;
  padding: 21px 15px;
  border-top-right-radius: 14px;
}

.leaderboard-title-row h5 {
  width: 100%;
  font-size: 1rem;
  line-height: 24px;
  text-align: center;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  margin-bottom: 0;
}

.leaderboard-title-row .fa-chevron-left {
  padding-top: 4px;
  vertical-align: top;
}

.dashboard-leaderboard-row:after {
  content: '';
  height: 1px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.12);
}

.dashboard-leaderboard-row .rank {
  width: 25px;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
  margin-right: 10px;
}

.dashboard-leaderboard-row .avatar {
  position: relative;
  width: 60px;
  overflow: hidden;
  svg {
    border: 2px solid #ffffff;
    border-radius: 50%;
  }
  .initials {
    position: absolute;
    top: 7px;
    left: 10px;
    text-transform: uppercase;
  }
  .initials-one {
    left: 14px;
    text-transform: uppercase;
  }
}

.dashboard-leaderboard-row .avatar img {
  border-radius: 50%;
  width: 46px;
  height: 46px;
}

.dashboard-leaderboard-row .user {
  width: 240px;
  height: 42px;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard-leaderboard-row .user .info {
  font-size: 10px;
}

.dashboard-leaderboard-row .points {
  width: 40px;
  padding-top: 16px;
  text-align: right;
  font-size: 1rem;
}

.dashboard-leaderboard-row-info {
  height: 31px;
  padding-top: 5px;
}

.dashboard-leaderboard-row-info:after {
  background-color: rgba(0, 0, 0, 0.5);
}

.dashboard-leaderboard-row-info .rank {
  padding-top: 0;
}

.dashboard-leaderboard-row-info .label {
  font-size: 12px;
}

.primary-bg {
  background-color: theme-color('primary');
}

.purple-bg {
  background-color: rgba(151, 47, 254, 0.3);
}

.dashboard-simulation-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px;
  z-index: 1001;
  justify-content: center;
  display: flex;
  color: #fff;
}

.simulation-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 25px;
  position: relative;
  width: 150px;
}

.simulation-item:after {
  content: '';
  top: 20px;
  bottom: 20px;
  right: 0;
  position: absolute;
  width: 1px;
  background-color: #fff;
}

.simulation-item:last-child:after {
  background-color: transparent;
}

.simulation-item .simulation-name {
  text-transform: uppercase;
  font-size: 12px;
}

.simulation-item .simulation-value {
  font-size: 58px;
  line-height: 60px;
}

.simulation-unit {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.7);
}

.leaderboard-arrow {
  width: 20px;
  height: 20px;
  text-align: right;
  cursor: pointer;
}

.dashboard-leaderboard.hidden {
  width: 35px;
  overflow: hidden;
  .rank {
    display: none;
  }
}

.dashboard-leaderboard.hidden .leaderboard-title-row {
  display: block;
}

.dashboard-leaderboard.hidden .dashboard-leaderboard-row {
  background: transparent;
}

.dashboard-leaderboard.hidden .dashboard-leaderboard-row * {
  color: transparent;
}

.dashboard-leaderboard.hidden .dashboard-leaderboard-row .fa-chevron-right {
  color: #fff;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.dashboard-leaderboard.hidden
  .dashboard-leaderboard-row
  .fa-chevron-right:hover {
  color: #fff;
}

.dashboard-leaderboard.hidden .dashboard-leaderboard-row:after {
  background: transparent;
}

.dashboard-leaderboard.hidden h5 {
  display: none;
}

.start-simulation-btn {
  width: 170px;
}

@media only screen and (max-width: 998px) {
  .dashboard-leaderboard {
    display: none;
  }

  .simulation-item .simulation-value {
    font-size: 28px;
    line-height: 30px;
  }

  .dashboard-player .video-js .vjs-remaining-time .vjs-remaining-time-display {
    font-size: 20px;
  }

  .dashboard-player .video-js .vjs-remaining-time {
    width: 8em;
  }

  .dashboard-player .video-js .vjs-progress-control {
    margin-left: 70px;
  }

  .simulation-item {
    width: 125px;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-player {
    .video-js {
      .vjs-control-bar {
        width: 100% !important;
      }
      .vjs-progress-control {
        margin: 0 15px !important;
      }
      .vjs-current-time {
        top: -15px;
        right: 20px;
      }
    }
    .timeline-toggler {
      right: -3px;
    }
    .vjs-icon-placeholder:before,
    .vjs-icon-play:before {
      top: 15px;
      left: -32px;
    }
  }
  .dashboard-survey {
    top: 85px;
    right: 10px;
    font-size: 14px;
    padding: 10px 15px;
  }
  .dashboard-back {
    top: 85px;
    left: 10px;
    font-size: 14px;
    padding: 10px 15px;
  }
  .dashboard-playlist {
    .playlist-focus {
      display: none;
    }
  }
  .play-video-btn,
  .pause-video-btn {
    width: 84px;
    height: 84px;
  }
  .display-play-btn.animate-play-btn .play-video-btn,
  .display-pause-btn.animate-pause-btn .pause-video-btn {
    opacity: 0;
    width: 84px;
    height: 84px;
    transition: all 0.5s;
  }
  .dashboard-playlist {
    display: none;
  }
}
