.navigation{
    &.navigation-bottom {
        .navbar {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .profile-name{
        font-size: 1rem;
    }

    .modal{
        .close {
            position: absolute;
            top: 1rem;
            right: 1rem; 
            i {
                font-weight: 100;
            }
            &:active {
                outline: none;
            }
        }
        .logout-icon {
            width: 132px;
            height: 132px;
            background: rgba(235, 87, 87, 0.1);
            border-radius: 94px;
            display: block;
            margin: 1rem auto;
            svg {
                position: absolute;
                top: 45px;
                left: 52%;
                transform: translateX(-50%);
            }
        }

        h4 {
            font-weight: 600;
        }

        p {
            font-weight: 500;
            font-size: 0.85rem;
            color: #828282;
        }

        .modal-body {
            padding: 2rem;
            background: #ffffff;
        }

        .modal-content{
            background-color: theme-color('light');
            border-radius: 0.5rem;
        }
        .modal-header{
            border-radius: 0;
            background-color: theme-color('primary');
            button{
                color: theme-color('light');
            }
        }
        .modal-title{
            color: theme-color('light');
        }
    }

    nav {
        justify-content: space-between;
        .fa-power-off{
            cursor: pointer;
        }

        .logo{
            height: 40px;
        }

        a{
            margin-right: 3rem;
            span{
                padding-left: 8px;
                color: theme-color("dark");
            }
        }

        box-shadow: 0px 2px 3px 0px;

        .rounded-circle {
            width: 25px;
            height: 25px;
        }

        .nav-link {
            i, span {
                color: theme-color("dark");
            }

            svg {
                stroke: theme-color("dark");
            }
        }

        .active {
            i, span {
                color: theme-color("primary");
            }

            svg {
                stroke: theme-color("primary")!important;
            }
        }
    }

    .navbar-container {
        flex-direction: row;
    }
      
    .navbar-container-right {
        flex-direction: row;
        text-align: right;
    }
    
    .switch-profile-icon {
        width: 26px;
        height: 26px;
    }

    .switch-profile-wrapper {
        span {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.25rem;
          margin-left: 0.5rem;
          color: theme-color('background-dark');
          &.switch-profile-active {
              border-radius: 1rem;
              padding: 0.3rem 1rem;
              background: theme-color('background-dark');
              color: theme-color('white');
          }
          i {
              padding: 0 0.2rem;
          }
        }
    }
}

