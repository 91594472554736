.mobile-logo-container {
  padding: 10px 10px 10px 0;
  float: left;
  text-align: center;
}

.logo-desc {
  float: right;
  padding-top: 10px !important;
  padding-left: 5px;
  margin-bottom: 0;
}

.logo-container {
  padding-left: 0;
}