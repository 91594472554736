.item-widget {
  overflow: hidden;
  margin-bottom: 8px;
  max-height: 300px;
  height: auto;
  .btn-dark {
    font-size: 14px;
    padding: 10px;
  }
  .item-link {
    text-decoration: none;
  }
  .item-wrapper,
  .item-dark-bg,
  .item-dark-bg-bottom,
  .item-link,
  .item-widget-bg {
    border-radius: 20px !important;
  }
  .item-dark-bg {
    position: absolute;
    height: 80px;
    right: 0;
    left: 8px;
    width: calc(100% - 1rem);
    top: 8px;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.9) 100%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
  .item-dark-bg-bottom {
    position: absolute;
    height: 80px;
    right: 0;
    left: 8px;
    bottom: 0;
    width: calc(100% - 1rem);

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.9) 100%
    );
  }
  &.achievement-widget {
    .faded-bg {
      max-height: 210px;
    }
    .instructor {
      font-size: 10px;
    }
  }
  .item-widget-bg {
    background-color: darkgrey;
    overflow: hidden;
    background-position: center;
    background-position: top;
    min-height: 290px;
    max-height: 100%;
    height: auto;
  }

  .faded-bg {
    background-color: rgba(0, 0, 0, 0.2);
  }

  h5,
  p {
    text-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.5) !important;
    z-index: 10;
    .checkbox-solid {
      width: 20px;
      margin-left: 0.5rem;
    }
  }

  background-size: cover;

  .instructor {
    font-size: 1rem;
    color: #ffffff;
  }

  .ratings {
    small {
      margin-left: 10px;
      vertical-align: bottom;
      color: #ffffff;
    }
  }

  .info {
    position: relative;
  }

  .studio-name {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #ffffff;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    padding: 1.5rem 1.5rem 7rem 1.5rem;
  }
  .item-favorite-wrapper {
    position: absolute;
    top: 30px;
    right: 30px;
    background: none;
    border: none;
    color: #fff;
    outline: none;
    .fas {
      color: theme-color("danger");
    }
  }
  .item-achievement-badge-wrapper {
    .current-badge {
      position: absolute;
      top: 10px;
      right: 30px;
    }
    .next-badge {
      position: absolute;
      top: 10px;
      right: 10px;
      opacity: 0.2;
    }
    img {
      width: 28px;
      height: 36px;
    }
  }

  .class-completed-checkbox {
    position: absolute;
    top: 15px;
    position: absolute;
    top: 30px;
    right: 65px;
  }
}

@media only screen and (max-width: 768px) {
  .item-widget {
    .studio-name {
      padding-bottom: 7rem;
    }
  }
}
