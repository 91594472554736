.overview-content {
  background-color: theme-color("background-light");
  .top-overview {
    background: #ffffff;
    overflow: hidden;
    .top-left-overview {
      h3 {
        font-weight: 300;
        font-size: 4rem;
        display: flex;
        align-items: center;
        text-align: center;
        color: #333333;
      }
      p {
        color: #828282;
        font-weight: normal;
        font-size: 1rem;
        text-align: left;
        padding-top: 1rem;
        padding-left: 0.5rem;
      }
    }

    .top-left-bottom-overview {
      .top-left-content {
        padding-left: 0px;
        h4 {
          font-weight: 500;
          font-size: 1.5rem;
          color: #333333;
        }
        p {
          font-weight: normal;
          font-size: 1rem;
          color: #828282;
          strong {
            color: #414141;
          }
        }
      }
    }

    .top-right-content {
      h4 {
        font-weight: 500;
        font-size: 1.75rem;
        color: #333333;
      }
      .top-right-link {
        position: absolute;
        top: 1rem;
        right: 4rem;
      }
      .achievement-wrapper {
        overflow-x: hidden;
        white-space: nowrap;
        .achievement-item {
          display: inline-block;
          figure {
            margin-bottom: 0;
            img {
              margin-bottom: 0;
              max-height: 147px;
            }
            &.figure-img-disabled {
              img {
                opacity: 0.2;
              }
            }
          }
          &:last-child {
            padding-left: 10px;
          }
        }
      }
    }
  }

  .bottom-overview {
    .bottom-calendar {
      background: #ffffff;
      border-radius: 1rem;
      box-shadow: 2px 2px 2px 0px lighten(theme-color("secondary"), 0.75);
      -webkit-box-shadow: 2px 2px 2px 0px
        lighten(theme-color("secondary"), 0.75);
      -moz-box-shadow: 2px 2px 2px 0px lighten(theme-color("secondary"), 0.75);

      h4 {
        font-weight: 500;
        font-size: 1.75rem;
        line-height: 2.625rem;
        color: theme-color('background-dark');
      }

      .react-calendar {
        width: 100%;
        border: none;
        &__tile {
            font-weight: normal;
            font-size: 1.5rem;
            line-height: 2.25rem;
            text-align: center;
            color: #4F4F4F;
            border-radius: 100%;
            padding: 14px;
            border: 5px solid theme-color('white');        
            margin-bottom: 0.50rem;
            &--now {
                position: relative;
                background: none;
                font-weight: bold;
                &:after {
                    content: "\2022";
                    position: absolute;          
                    bottom: 24px;
                    left: 42%;
                    width: 5px;
                    height: 5px;
                    color: theme-color('danger');
                }
            }
            &:enabled:hover, 
            &:enabled:focus {
                background: theme-color('primary');
                color: theme-color('white');
            }
            &:disabled {
              background: theme-color('white');
            }
            &.day-has-exercises {
                background: theme-color('primary');
                color: theme-color('white');
            }
        }
        &__month-view {
          &__days {
              &__day {
                  &--neighboringMonth {
                      color: theme-color('disabled-font');
                  }
              }
          }  
          &__weekdays {
            &__weekday {
              font-weight: 600;
              font-size: 1rem;
              color: theme-color('dark');
              text-transform: none;
              &:last-child {
                color: theme-color("danger");
              }

              abbr[title],
              abbr[data-original-title] {
                text-decoration: none;
              }
            }
          }
        }
      }

      .bottom-calendar-overview {
          h3 {
            font-weight: 300;
            font-size: 4rem;
            line-height: 6.5rem;
            text-align: center;
            color: #333333;
          }
          h6 {
            font-weight: normal;
            font-size: 1rem;
            line-height: 1.5rem;
            text-align: center;
            color: theme-color('dark');
          }
          .bottom-overview-summary {
            margin: 0 auto;
            max-width: 180px;
            background: rgba(235, 87, 87, 0.1);
            border-radius: 10px;
            text-align: center;
            padding: 0.3125rem;
            color: theme-color('danger');
            font-size: 0.875rem;
            line-height: 1.3125rem;
            text-align: center;
          }
          .bottom-overview-list {
              .overview-list-item {
                  border-top: 1px solid theme-color('secondary');
                  .item-val {
                    font-weight: 300;
                    font-size: 2rem;
                    line-height: 3rem;
                    color: theme-color('background-dark');
                    small {
                        font-size: 0.8rem;
                        line-height: 1rem;
                    }
                  }
                  .item-progress {
                      color: theme-color('text-green');
                      &.item-progress-down {
                          color: theme-color('danger');
                      }
                  }
              }
          }
      }
    }

    .bottom-stats {
        .bottom-stats-item {
            background: theme-color('white');
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
            border-radius: 20px;
            p {
                margin-bottom: 0;
            }
            .item-day {
                span {
                    display: block;
                    font-weight: 600;
                    font-size: 0.625rem;
                    line-height: 0.9375rem;
                    text-align: center;
                    letter-spacing: 0.15em;
                    text-transform: uppercase;
                    color: theme-color('dark');
                }
                p {
                    font-weight: 300;
                    font-size: 1.5rem;
                    line-height: 2.25rem;
                    text-align: center;
                    color: theme-color('background-dark');
                }
            }
            .item-desc {
                h4 {
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: theme-color('background-dark');
                }
                p {
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 0.875rem;
                    color: theme-color('dark');
                    svg {
                        margin-right: 5px;
                    }
                }
            }
            .item-total {
                p {
                    font-weight: 300;
                    font-size: 1.5rem;
                    line-height: 2.25rem;
                    text-align: center;
                    color: theme-color('background-dark');
                    small {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
  }
}
