.account {
  padding-bottom: 1rem;
  .stripe-subscription-row-info {
    color: #666785;
  }

  .stripe-subscription-row-info .value {
    color: #272a2e;
  }

  span.stripe-subscription-status {
    text-transform: capitalize;
  }

  p.stripe-subscription-status::first-letter {
    text-transform: uppercase;
  }

  .subscription-active-wrapper {
    .btn-light-transparent {
      text-transform: none !important;
      border-radius: 10px !important;
    }
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: theme-color("secondary");
      strong {
        color: theme-color("white");
      }
    }
  }

  .subscribe-img-wrapper {
    position: relative;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    background-image: url("../../images/subscribe-bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 450px;
    .bg-gradient {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        260deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    img {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }

  .subscribe-card-wrapper {
    background: theme-color("black");
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    h2 {
      font-weight: 600;
      font-size: 2.625rem;
      line-height: 3.9375rem;
      color: theme-color("white");
    }
    h3 {
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.5rem;
      color: theme-color("white");
    }
    h4 {
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2rem;
      color: theme-color("white");
    }
    p {
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.875rem;
      color: theme-color("text-gray");
    }
    .stripe-subscription-status {
      color: theme-color("danger");
    }
  }

  .subscribe-modal-wrapper {
    .subscribe-dialog-content {
      border-radius: 20px;
      h3 {
        font-weight: 600;
        font-size: 2.625rem;
        line-height: 3.9375rem;
        color: theme-color("background-dark");
      }

      .modal-body {
        .subscribe-middle {
          border: 2px solid theme-color("secondary");
          border-radius: 14px;
        }
        .subscribe-price-text {
          text-align: left;
          border-right: 2px solid theme-color("secondary");
          p {
            font-weight: 600;
            font-size: 2rem;
            line-height: 3rem;
            color: theme-color("background-dark");
            margin-bottom: 0;
          }
        }
        .subscribe-cancel-text {
          text-align: left;
          p {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.3125rem;
            color: theme-color("dark");
          }
        }
        .close {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          opacity: 1;
        }
      }

      .divider {
        display: none;
      }

      .subscribeButton {
        margin-top: 3px !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        color: theme-color("white");
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .account {
    .subscribe-img-wrapper {
      min-height: 345px;
      .bg-gradient,
      img {
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;
        border-bottom-left-radius: 0;
      }
    }

    .subscribe-card-wrapper {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
      h2,
      h3 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      .subscription-price-header {
        font-size: 1.3rem;
      }
      p {
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
      .btn-light-transparent {
        width: 100%;
      }
      .btn-main {
        width: 100%;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
      }
      .subscribe-dialog-content {
        h3 {
          font-size: 1.5rem;
          line-height: 2rem;
        }
        .modal-body {
          .subscribe-price-text {
            text-align: center;
            padding-bottom: 1rem;
            border-right: 0;
            border-bottom: 2px solid #f2f2f2;
          }
          .subscribe-cancel-text {
            padding-top: 1rem;
            p {
              font-size: 0.75rem;
              text-align: center;
            }
          }
        }
        .subscribeButton {
          font-size: 1rem;
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }
  }
}
