.classes {
  .last-filter {
    padding-right: 15px;
  }
  .items-wrapper {
    display: flex;
    flex-wrap: wrap;
    //.item {
    //    width: 33.33%;
    //    box-sizing: border-box;
    //}
  }

  .empty-placeholder {
    max-width: 600px;
    h3 {
      font-weight: 600;
      color: #e0e0e0;
    }
  }

  .favorite-wrapper {
    button {
      border: none;
      outline: none;
      box-shadow: none;
      padding: 12px;
      border-radius: 1rem;
      background: theme-color("secondary");
      color: theme-color("text-gray");
      .favorite-text {
        font-size: 14px;
        color: theme-color("text-gray");
        margin-left: 8px;
      }
    }
    .dot-separator {
      font-size: 25px;
      color: #e0e0e0;
      vertical-align: middle;
    }
    .clear-filters-btn {
      font-size: 14px;
      color: theme-color("primary");
      background: lighten(theme-color("primary"), 20%);
    }
  }

  .class-session-filters [class$="control"] {
    border: none;
    box-shadow: none;
  }

  .class-session-filters [class$="indicatorSeparator"] {
    background: none;
    box-shadow: none;
  }

  .class-session-filters [class$="placeholder"] {
    font-size: 14px;
    box-shadow: none;
  }
}

@media only screen and (max-width: 768px) {
  .custom-class-dropdown {
    min-height: auto !important;
    &__value-container {
      height: auto;
      padding: 0 !important;
    }
  }
  .custom-class-dropdown-single.custom-class-dropdown__highlighted {
    top: -5px;
  }
  .custom-class-dropdown__menu {
    width: 200px !important;
    .custom-class-dropdown__option {
      font-size: 12px !important;
    }
    .custom-class-dropdown__indicator {
      display: none;
    }
  }
  .classes {
    overflow-x: hidden;
    .items-wrapper {
      margin-top: 0 !important;
    }
    .class-session-filter-wrapper {
      padding-left: 1.5rem !important;
      padding-right: 0 !important;
    }
    .class-mobile-filters {
      overflow-x: auto;
      white-space: nowrap;
    }
    .class-session-filters {
      margin-top: 3rem;
      max-width: 100%;
      .favorite-wrapper {
        float: left;
        button {
          width: 44px;
          height: 44px;
          border-radius: 100%;
        }
        .bull-separator {
          color: #e0e0e0;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .class-filter-item {
        min-width: 120px;
        display: inline-block;
        float: none;
      }
    }

    .item-widget {
      p {
        z-index: 1;
      }
    }

    .item-wrapper {
      .instructor {
        font-size: 0.75rem;
      }
      .info {
        margin-left: 1rem !important;
        .btn-dark {
          padding: 8px;
          font-size: 12px;
          margin-bottom: 5px;
          svg {
            margin-right: 5px;
          }
        }
      }
      .item-favorite-wrapper {
        right: 20px;
      }
      .class-completed-checkbox {
        right: 50px;
      }
    }
  }
}
