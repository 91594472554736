.btn-katana {
    color: #fff;
    background-color: theme-color("primary");
    border-color: theme-color("primary");
}

.base-color {
    color: theme-color("primary");
}

.landing-page {
    color: white;
    text-align: center;
}

.landing-page .background-image {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../../images/slider-img.png");
}

.landing-page .title {
    margin: 10% 0 0 0;
    font-weight: bold;
}

.landing-page .sub-title {
    margin: 35% 0 0 0;
    font-weight: bold;
}

.landing-page .description {
    width: 90%;
    color: #c3bfbf;
    margin: auto;
}

.landing-page .content {
    height: 100%;
}