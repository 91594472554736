@import url("https://fonts.googleapis.com/css?family=Ubuntu");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

$theme-colors: (
  "dark": #828282,
  "primary": #fcb333,
  "secondary": #f2f2f2,
  "background-light": #fbfbfb,
  "background-dark": #333333,
  "background-darker": #1e1e1e,
  "primary-light": rgba(252, 179, 51, 0.2),
  "text-gray": #808080,
  "dark-gray": #f4f5fa,
  "text-green": #27ae60,
  "light-gray": #e4e2e8,
  "light": #f4f5fb,
  "white": #ffffff,
  "black": #000000,
  "light-font": #818999,
  "disabled-font": #bdbdbd,
  "cadence": #4c2ffe,
  "output": #694a4d,
  "danger": #eb5757,
);

$spacer: 1rem;
$spacers: (
  6: (
    $spacer * 4,
  ),
  7: (
    $spacer * 5,
  ),
  8: (
    $spacer * 7,
  ),
  9: (
    $spacer * 10,
  ),
);

@import "~bootstrap/scss/bootstrap";
@import "loaders.css/src/animations/ball-beat.scss";

$primary-color: theme-color("primary");

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  right: 0;
  background: theme-color("white");
  z-index: 9999;
  .loader-inner {
    position: absolute;
    top: calc(50% - 1em);
    left: calc(50% - 1em);
    > div {
      background: theme-color("primary");
    }
  }
}

body {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  background-color: theme-color("light");
}

.bg-light {
  background-color: theme-color("background-light") !important;
}

.loading-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
}

.loading-container .fa-spinner {
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 20px;
  margin: auto;
}

.btn-light {
  background-color: theme-color("primary-light");
  color: theme-color("primary");
  &:hover {
    background-color: theme-color("primary-light");
    color: theme-color("primary");
  }
  &:focus {
    outline: darken(theme-color("primary"), 10%);
  }
}

.btn-main {
  background: theme-color("primary");
  color: theme-color("white");
  &:hover {
    color: theme-color("white");
  }
  &:disabled {
    color: theme-color("white");
  }
}

.btn-danger-light {
  background: rgba(235, 87, 87, 0.1);
  border-radius: 0.875rem;
  color: theme-color("danger");
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  text-align: center;
  &:hover {
    color: theme-color("danger");
  }
  &:active,
  &:focus {
    outline: none;
  }
}

.btn-cancel-light {
  background: rgba(252, 179, 51, 0.2);
  border-radius: 0.875rem;
  color: theme-color("primary");
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  text-align: center;
  &:hover {
    color: theme-color("primary");
    text-decoration: none;
  }
  &:active,
  &:focus {
    outline: none;
  }
}

.btn-save {
  background: theme-color("primary");
  border-radius: 0.875rem;
  color: theme-color("white");
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  text-align: center;
  &:hover {
    color: theme-color("white");
    text-decoration: none;
  }
  &:active,
  &:focus {
    outline: none;
  }
}

.btn-simple {
  background: none;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
}

.content-visible {
  opacity: 1;
  transition: all 250ms linear;
}

.content-hidden {
  opacity: 0;
  transition: all 250ms linear;
}

.btn-white {
  background: #ffffff;
  padding: 14px 28px;
  border-radius: 30px;
}

.plain-btn {
  border: none;
  background: none;
  outline: none;
  &:focus {
    outline: none;
  }
}

.btn-dark {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 14px;
  padding: 14px;
  border: none;
  outline: none;
  z-index: 11;
  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
  svg {
    margin-right: 10px;
  }
}

.btn-light-transparent {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  padding: 15px 25px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  border: 0;
  outline: none !important;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  &:active {
    outline: none;
  }
  small {
    text-transform: lowercase;
  }
}

.has-errors {
  label {
    color: #eb5757 !important;
  }
  input {
    background: rgba(235, 87, 87, 0.1) !important;
    border: 2px solid #eb5757 !important;
  }
}

.custom-class-dropdown {
  min-height: 48px !important;
  .input-default-val {
    font-size: 0.875rem;
  }
  .instructor-default-val {
    margin-left: 10px;
  }
  .music-default-val {
    margin-left: 6px;
  }
  .class-default-val {
    margin-left: 5px;
  }
  .count-selected {
    color: theme-color("primary");
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    font-style: normal;
    line-height: 20px;
  }

  &__highlighted {
    .custom-class-dropdown__control {
      color: #ffffff !important;
      background-color: theme-color("primary") !important;
    }
    .custom-class-dropdown__placeholder {
      color: #ffffff !important;
    }
  }

  &__weights-content {
    .custom-class-dropdown__placeholder {
      margin-left: 26px !important;
      padding-left: 8px;
    }
  }

  &__control {
    color: #828282;
    background-color: #f2f2f2 !important;
  }

  &__menu {
    z-index: 999 !important;
  }

  &__menu-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__option {
    font-size: 0.875rem !important;
    padding: 12px !important;
    position: relative;
    &:nth-of-type(odd) {
      background-color: theme-color("background-light");
    }
    &:nth-of-type(even) {
      background-color: theme-color("secondary");
    }

    &--is-focused {
      background-color: theme-color("background-dark");
    }
    &--is-selected {
      background-color: theme-color("background-dark") !important;
      &:after {
        font-family: "Font Awesome 5 Free";
        content: "\f00c";
        font-weight: 900;
        position: absolute;
        right: 14px;
      }
    }
  }

  &__indicator {
    position: absolute;
    right: 5px;
  }

  &__value-container {
    width: 100% !important;
    height: 33px;
    padding: 2px 5px !important;
  }

  &__single-value {
    margin-left: 30px !important;
  }
}

.navigation-avatar-wrapper {
  position: absolute;
  right: 54px;
  top: 54px;
  .navigation-avatar-text {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1.25rem 2rem;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: theme-color("white");
    border-top-left-radius: 3.125rem;
    border-bottom-left-radius: 3.125rem;
    margin-right: -1rem;
  }
  .navigation-avatar {
    position: relative;
    width: 86px;
    overflow: hidden;
    svg {
      border: 2px solid #ffffff;
      border-radius: 50%;
    }
    .initials {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      text-transform: uppercase;
    }
  }
}

.classes,
.leaderboard-page {
  .navigation-avatar-wrapper {
    top: 30px;
    right: 15px;
    z-index: 1005;
    .navigation-avatar-text {
      color: theme-color("dark");
    }
  }
}

@media only screen and (max-width: 768px) {
  .btn-signout-modal {
    padding-right: 1.5rem !important;
  }
}
