.profiles-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 64px;
  left: 0;
  background-color: theme-color("background-light");

  .profiles-centered {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .profiles-label {
    margin-bottom: 4rem;
    font-weight: 600;
    font-size: 3.375rem;
    line-height: 5.0625rem;
    color: theme-color("background-dark");
  }

  .profiles-list {
    width: 100%;
    display: flex;
    justify-content: center;
    .profile-add-btn {
      border-radius: 50%;
      background: rgba(theme-color("primary"), 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .avatar {
    position: relative;
    width: 240px;
    overflow: hidden;
    svg {
      border: 2px solid theme-color("white");
      border-radius: 50%;
    }
    .initials {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: theme-color("white");
      font-size: 5rem;
      text-transform: uppercase;
    }
  }

  .avatar-name {
    padding-left: 1rem;
    font-weight: 600;
    font-size: 1rem;
    color: theme-color("background-dark");
  }

  .profile-item {
    max-width: 240px;
    min-width: 50px;
    max-height: 240px;
    min-height: 50px;
    margin: 0 15px;
    width: 20vw;
    height: 20vw;
    position: relative;
  }

  .profile-item-image {
    width: 100%;
    height: 100%;
    display: block;
    justify-content: center;
    background-color: theme-color("background-light");
    border-radius: 4px;

    .fa-pencil-alt {
      color: #fff;
      display: none;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 100%;
    }

    .profiles-avatar {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .profile-select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .manage-profiles-edit .profile-item-image {
    .fa-pencil-alt {
      display: block;
      position: absolute;
      font-size: 3vw;
    }

    .fa-pencil-alt:hover {
      text-decoration: none;
    }

    .profile-select {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  .profile-item-label {
    font-size: 1.5vw;
    margin-top: 1.7vw;
    text-align: center;
    border-radius: 30px;

    cursor: pointer;
    &:hover {
      transition: all 0.1s ease-in;
      color: theme-color("white");
      background-color: theme-color("primary");
    }
  }

  .profile-form-wrapper {
    border-radius: 20px;
  }

  .profile-fields-wrapper {
    padding: 4rem 2rem;
  }

  .avatar-wrapper {
    border-right: 1px solid theme-color("secondary");
  }

  .form-control {
    border-radius: 7px;
    color: theme-color("background-dark");
    background: theme-color("secondary");
    border: none;
    padding: 0.9rem;
    margin-bottom: 0.5rem;
    height: auto;
    border: transparent solid 2px;
  }

  label {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: theme-color("dark");
    margin-bottom: 0.5rem;
  }

  .forgot-pwd-link {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1rem;
    text-align: right;
    color: theme-color("primary");
  }

  .form-control::placeholder {
    color: theme-color("disabled-font");
  }

  input:active,
  input:focus {
    background: theme-color("white");
    border: #fcb333 solid 2px;
    box-shadow: none !important;
  }

  .input-group {
    border-radius: 10px;
  }

  .input-group-text {
    background: transparent;
    border: none;
    color: #78909c;
  }

  .gender {
    border-radius: 10px;
    background: theme-color("secondary");
    button {
      background: theme-color("secondary");
      border-radius: 10px;
      margin: 5px 0 5px 5px;
      font-size: 0.8em;
      width: 48%;
      border: none;
      padding: 0.9rem 0;
      color: theme-color("background-dark");
      &:active,
      &:focus {
        outline: none;
      }
    }

    .female {
    }

    .male {
    }

    .selected {
      background-color: theme-color("primary") !important;
      color: #fff;
    }
  }

  .manage-profiles-button {
    margin-top: 10vw;
    font-size: 1.2rem;
    border-radius: 20px;
    color: theme-color("text-gray");
    svg {
      margin-right: 5px;
    }
    &:hover {
      background-color: theme-color("secondary");
      color: theme-color("text-gray");
    }
  }
}

@media only screen and (max-width: 768px) {
  .profiles-container {
    background-color: theme-color("white");
    .profiles-label {
      font-size: 2rem;
      line-height: 2.2rem;
      margin-bottom: 1rem;
      margin-top: 2rem;
    }
    .profile-item-label {
      font-size: 1rem;
    }

    .profiles-list {
      display: block;
      .profile-item {
        margin-bottom: 1rem;
        width: 41%;
        height: auto;
        float: left;
        .profile-item-image {
          background-color: theme-color("white");
          .avatar {
            text-align: center;
            width: 100%;
            .initials {
              font-size: 3rem;
            }
            svg {
              border: none;
              max-width: 100%;
            }
          }
        }
        .profile-add-btn {
          height: 40vw;
          border-radius: 50%;
          background: rgba(theme-color("primary"), 0.2);
        }
      }
    }
  }
}
