.subscription {
  .subscription-content {
    min-height: 450px;
  }
  padding-bottom: 70px;
}

.subscribeButton {
  margin-top: 0 !important;
}

.divider {
  height: 20px;
  background: white;
}

.cardElementContainer {
  padding: 20px 7px;
}