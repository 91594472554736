.auth {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fbfbfb;
  overflow: auto;

  .link-item {
    color: theme-color("primary");
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  .logo {
    position: absolute;
    top: 70px;
    right: 37%;
    left: auto;
  }

  .copyrights-content {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    padding: 2.375rem 0;
    p {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      color: #bdbdbd;
    }
  }

  .email-sent-content {
    .email-icon {
      position: relative;
      display: block;
      background: rgba(252, 179, 51, 0.2);
      border-radius: 100px;
      width: 148px;
      height: 148px;
      margin: 0 auto;
      i {
        color: theme-color("primary");
        position: absolute;
        top: 50px;
        left: 52%;
        transform: translateX(-50%);
      }
    }
    p {
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 2.625rem;
    }
    small {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      color: #9399a3;
    }
    .btn-main {
      margin-top: 171px;
    }
  }

  a:hover {
    text-decoration: none;
  }

  .alert-danger {
    font-size: 14px;
  }

  .background-image {
    padding: 0 6rem;
    background-color: #333333;
    height: 600px;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .quote-content {
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 2rem;
    letter-spacing: -1px;
    text-align: center;

    strong {
      font-weight: 700;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 70%;
      margin: 10px auto;
      width: 52px;
      height: 2px;
      background: #ffffff;
    }
  }
  cite {
    color: #999999;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .btn-outline-light:hover {
    color: theme-color("primary");
  }

  .form-box p {
    color: #969191;
  }

  .form-box {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;

    input:active,
    input:focus {
      background: #ffffff;
      border: #fcb333 solid 2px;
      box-shadow: none !important;
    }

    .form-control {
      border-radius: 7px;
      color: theme-color("background-dark");
      background: #f2f2f2;
      border: none;
      padding: 10px 15px;
      height: auto;
      border: transparent solid 2px;
      &::placeholder {
        color: #bdbdbd;
      }
      &.is-invalid {
        border: 2px solid theme-color("danger") !important;
        background: rgba(theme-color("danger"), 0.15) !important;
        color: theme-color("background-dark");
      }
    }

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      color: #828282;
    }

    .forgot-pwd-link {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1rem;
      padding-top: 1rem;
      text-align: right;
      color: theme-color("primary");
    }
    .input-group {
      border-radius: 10px;
    }

    .input-group-text {
      background: transparent;
      border: none;
      color: #78909c;
    }
  }

  .link-box {
    color: #fff;
    text-align: center;
  }

  .validated {
    flex: 1;
  }

  .validation-message {
    color: #ff2b1a;
    font-size: 0.75rem;
  }

  .katana-terms {
    font-size: 0.75rem !important;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .auth {
    background: theme-color("white");
    overflow-x: hidden;
    .row.h-100 {
      height: auto !important;
    }
    .auth-content-wrapper {
      align-self: normal !important;
      box-shadow: none !important;
    }
    .top-logo-wrapper {
      padding: 1rem 0;
      align-self: normal !important;
      background-color: theme-color("background-dark");
      .logo-text {
        color: theme-color("white");
        padding-top: 0.25rem;
        text-transform: uppercase;
      }
    }
    .form-box {
      box-shadow: none !important;
    }
    .logo {
      position: relative;
      top: initial;
      right: initial;
      left: initial;
    }
    .background-image {
      display: none;
    }
    .form-box {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .login-form-wrapper {
      padding: 2rem !important;
      h2 {
        font-size: 1.5rem;
      }
      .link-item {
        font-size: 0.875rem;
        margin: 0 !important;
      }
    }
    .form-check {
      padding: 0;
    }
    .copyrights-content {
      background: theme-color("white");
      padding-bottom: 0;
      bottom: -100px;
      border: none;
    }
  }
}
