.featured-wrapper {
  position: relative;
  min-height: 778px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .bg-gradient {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, rgba(25, 39, 74, 0) 0%, #19274a 100%);
  }

  .items-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10rem;
  }

  .play-icon-content {
    position: absolute;
    top: 30%;
    left: 55%;
    transform: translateX(-50%);
    z-index: 99;
    .play-icon {
      position: relative;
      width: 188px;
      height: 188px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.4);
      svg {
        position: absolute;
        top: 60px;
        left: 52%;
        transform: translateX(-50%);
      }
    }
  }

  .featured-class {
    h5 {
      font-weight: 600;
      font-size: 42px;
      line-height: 63px;
    }

    background-size: cover;

    .faded-bg {
      min-height: 500px;
      background-color: rgba(0, 0, 0, 0.5);

      .view-class {
        font-size: 0.9rem;
        padding: 0.6rem 2.7rem;
        font-weight: bold;
        margin-top: 3rem;
      }
    }

    .ratings {
      small {
        font-weight: 500;
        font-size: 1rem;
        line-height: 14px;
        margin-left: 10px;
        vertical-align: bottom;
      }
    }

    .instructor {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }

    .studio-name {
      font-size: 1rem;
      color: theme-color("primary");
    }

    .description-content {
      color: #ffffff;
      opacity: 0.4;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    }
  }
}

.home {
  background-color: #19274a;
  margin-top: -200px;
  .suggested-items-wrapper {
    overflow-x: auto;
    white-space: nowrap;

    .btn-dark {
      font-size: 14px;
      padding: 10px;
      &:focus,
      &:hover {
        outline: none;
      }
    }

    .item-widget {
      display: inline-block;
      float: none;
      max-width: 480px;
    }
    .alice-carousel__next-btn-item,
    .alice-carousel__prev-btn-item {
      width: 40px;
      position: absolute;
      justify-content: center;
      vertical-align: middle;
      align-items: center;
      display: flex;
      background: rgba(theme-color("dark"), 0.2);
      color: theme-color("white");
      top: 8px;
      bottom: 35px;
      z-index: 100;
    }
    .alice-carousel__next-btn-item {
      right: 0;
    }
    .alice-carousel__prev-btn-item {
      left: 8px;
    }
  }
  h3 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

@media only screen and (max-width: 768px) {
  .home {
    margin-top: -100px;
    .suggested-items-wrapper {
      .btn-dark {
        padding: 8px;
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }
  .featured-wrapper {
    min-height: 650px;
    .navigation-avatar-wrapper {
      top: 1rem;
      right: 1rem;
    }
    .items-wrapper {
      position: absolute;
      bottom: 130px;
      margin-top: 2rem;
      h5,
      p,
      .info,
      .ratings {
        margin-left: 0 !important;
      }
      .btn-dark {
        font-size: 14px;
        padding: 6px;
      }
    }
    .play-icon-content {
      top: 30%;
      left: 50%;
      .play-icon {
        width: 80px;
        height: 80px;
        svg {
          top: 34%;
          left: 53%;
        }
      }
    }

    .featured-class {
      h5 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      .instructor {
        font-size: 0.875rem;
      }
      .info {
        button {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  .navigation-bottom {
    width: 100%;
    bottom: 0;
    position: fixed;
    padding: 1rem 1.25rem;
    background: theme-color("white");
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.05);
    z-index: 100;
    &.navigation-show {
      .hamburger-react {
        background-color: theme-color("black");
      }
    }
    .navigation-container-mobile {
      .logo {
        float: left;
        max-width: 140px;
      }
      .profile-icon-wrapper {
        position: absolute;
        right: 60px;
        .close-profile {
          position: relative;
          display: block;
          background: theme-color("black");
          color: theme-color("white");
          border-radius: 50%;
          width: 30px;
          height: 30px;
          text-align: center;
          i {
            position: absolute;
            top: 25%;
            transform: translateX(-50%);
          }
        }
      }
      .menu-icon-wrapper {
        position: absolute;
        right: 1rem;
      }
      .hamburger-react {
        position: absolute !important;
        border-radius: 50%;
        right: 5px;
        top: 0.25rem;
        &:focus {
          outline: none !important;
        }
        div {
          &:focus {
            outline: none !important;
          }
        }
      }
    }
    .main-nav-bottom {
      position: absolute;
      left: 10px;
    }
  }
  .navbar-bottom-responsive {
    position: fixed;
    bottom: 56px;
    width: 100%;
    z-index: 101;
    &:hover {
      color: theme-color("white");
      svg {
        stroke: theme-color("white") !important;
      }
      .nav-link {
        &:after {
          color: theme-color("white");
        }
      }
    }
    .nav-item {
      position: relative;
      background-color: theme-color("white");
      &:first-child {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        .nav-link {
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
      }
      &:last-child {
        &:after {
          font-family: "Font Awesome 5 Free";
          color: theme-color("disabled-font");
          content: "\f105";
          font-weight: 900;
          position: absolute;
          right: 5%;
          top: 25%;
          z-index: 100;
        }
      }

      .nav-link {
        border-bottom: 1px solid theme-color("secondary");
        color: theme-color("dark");
        padding: 1rem;
        svg {
          margin-right: 0.5rem;
          stroke: theme-color("dark");
        }

        &:hover {
          color: theme-color("white");
          background-color: theme-color("primary");
        }
        &.active {
          background: theme-color("primary");
          color: theme-color("white");
          svg {
            stroke: theme-color("white") !important;
          }
          &:after {
            color: theme-color("white");
          }
        }
        &:after {
          font-family: "Font Awesome 5 Free";
          color: theme-color("disabled-font");
          content: "\f105";
          font-weight: 900;
          position: absolute;
          right: 5%;
          top: 25%;
          z-index: 100;
        }
      }
      .btn-logout {
        color: theme-color("danger");
        display: block;
        width: 100%;
        text-align: left;
        padding: 1rem 1.5rem;
      }
    }
  }
}
