.profile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .under-avatar-content {
    h5 {
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 2.625rem;
      color: theme-color("background-dark");
    }
  }

  .manage-profiles-button {
    border-radius: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: theme-color("dark");
    svg {
      margin-right: 0.3125rem;
    }
  }

  .avatar {
    position: relative;
    width: 120px;
    overflow: hidden;
    svg {
      border: 2px solid theme-color("white");
      border-radius: 50%;
    }
    .initials {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: theme-color("white");
      font-size: 3rem;
      text-transform: uppercase;
    }
  }

  .avatar-name {
    padding-left: 1rem;
    font-weight: 600;
    font-size: 1rem;
    color: theme-color("background-dark");
  }

  .profile-nav-mobile {
    .border-rl {
      border-radius: 0 0.1rem 0.1rem 0;
    }
    .border-rr {
      border-radius: 0.1rem 0 0 0.1rem;
    }
    border-radius: 2px;
    a {
      font-size: 0.6em;
      border: none;
      padding: 0.3rem 0 0.3rem 0;
      color: theme-color("light-font");
    }
    a:hover {
      text-decoration: none;
    }

    .selected {
      background-color: theme-color("primary") !important;
      color: theme-color("white");
    }
  }

  .profile-nav {
    .border-rl {
      border-radius: 0 0.1rem 0.1rem 0;
    }
    .border-rr {
      border-radius: 0.1rem 0 0 0.1rem;
    }
    border-radius: 2px;
    a {
      font-size: 0.6em;
      width: 50%;
      border: none;
      padding: 0.3rem 0 0.3rem 0;
      color: theme-color("light-font");
    }
    a:hover {
      text-decoration: none;
    }

    .selected {
      background-color: theme-color("primary") !important;
      color: #fff;
    }
  }

  .profile-wrapper {
    margin: 1.5rem;
    border-radius: 20px;
    background-color: theme-color("white");
    h5 {
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 2.625rem;
      color: theme-color("background-dark");
    }

    .edit-btn-wrapper {
      padding-top: 7rem;
    }

    .avatar-content {
    }

    .personal-content {
      border-left: 1px solid theme-color("secondary");
      .form-control {
        border-radius: 7px;
        color: #333333;
        background: theme-color("secondary");
        border: none;
        padding: 1rem;
        margin-bottom: 0.5rem;
        height: auto;
        border: transparent solid 2px;
      }

      label {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: theme-color("dark");
        margin-bottom: 0.5rem;
      }

      .forgot-pwd-link {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1rem;
        text-align: right;
        color: theme-color("primary");
      }

      .form-control::placeholder {
        color: theme-color("disabled-font");
      }

      input:active,
      input:focus {
        background: theme-color("white");
        border: #fcb333 solid 2px;
        box-shadow: none !important;
      }

      .input-group {
        border-radius: 10px;
      }

      .input-group-text {
        background: transparent;
        border: none;
        color: #78909c;
      }
    }
  }

  .my-account {
    background: theme-color("background-light");
    .validation-message {
      //position: absolute;
      color: #ff2b1a;
      font-size: 14px;
    }

    .spinner-grow {
      width: 1.1rem;
      height: 1.1rem;
    }

    .selected {
      .overlay {
        opacity: 0;
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(39, 42, 46, 0.5);
    }

    .editing {
      .img-container {
        cursor: pointer;
      }
      .overlay {
        opacity: 0;
      }

      .selected {
        img {
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        }
        .overlay {
          border: 2px solid theme-color("primary");
          background: none;
          opacity: 1;
        }
      }
    }

    .edit-box {
      img {
        max-width: 100%;
        height: auto;
      }

      input {
        font-size: 0.8em;
        border: none;
        border-radius: 2px;
      }

      label {
        font-size: 0.9em;
      }

      .btn {
        cursor: pointer;
        font-size: 0.9em;
      }

      .fa-save {
        border: none;
        border-bottom: 2px solid black;
        background-color: #fff;
      }

      .fa-pencil-alt {
        border: none;
        border-bottom: 2px solid black;
        background-color: #fff;
      }

      .gender {
        border-radius: 10px;
        background: theme-color("secondary");
        button {
          background: theme-color("secondary");
          border-radius: 10px;
          margin: 5px 0 5px 5px;
          font-size: 0.8em;
          width: 48%;
          border: none;
          padding: 0.9rem 0;
          color: theme-color("background-dark");
          &:active,
          &:focus {
            outline: none;
          }
        }

        .female {
        }

        .male {
        }

        .selected {
          background-color: theme-color("primary") !important;
          color: #fff;
        }
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  p {
    font-size: 13px;
  }

  .profile-menu-wrapper {
    position: absolute;
    bottom: 75px;
    width: 100%;
    left: 0;
  }

  .profile-menu {
    a {
      color: theme-color("light-font");
      padding: 1.2rem 1.5rem;
      font-weight: 500;
      font-size: 1rem;
      color: theme-color("dark");
      border-bottom: 1px solid theme-color("secondary");
      &:first-child {
        border-top: 1px solid theme-color("secondary");
      }
      &:last-child {
        border-bottom: none;
      }
    }

    font-size: 14px;

    i {
      font-size: 16px;
    }

    svg {
      margin-right: 1rem;
    }

    .active {
      color: #ffffff;
      background-color: theme-color("primary");
      border-radius: 0;
      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
  }

  .workouts {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;

    .workout-list {
      a {
        color: theme-color("dark");
        text-decoration: none;

        :hover {
          background-color: theme-color("background-dark");
          color: theme-color("white");
          text-decoration: none;
          .class-title {
            h4 {
              color: theme-color("white");
            }
          }
          .total-value,
          .total-unit,
          .date {
            color: theme-color("white") !important;
          }
        }

        :focus {
          background-color: theme-color("background-dark");
          color: theme-color("white");
          text-decoration: none;
        }
      }

      .active-workout li {
        color: theme-color("white");
        background-color: theme-color("background-dark");
        .class-title {
          h4 {
            color: theme-color("white");
          }
        }
        .total-value,
        .total-unit,
        .date {
          color: theme-color("white") !important;
        }
      }

      .day {
        font-weight: 600;
        font-size: 0.625rem;
        line-height: 0.9375rem;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: theme-color("secondary");
      }

      .equipment {
        font-size: 0.4rem;
      }

      .class-title {
        h4 {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;
          color: theme-color("background-dark");
        }
      }

      .date {
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 2.25rem;
        text-align: center;
        color: theme-color("background-dark");
      }

      .list-group-item {
        padding: 1rem 1.75rem;
      }

      .month-divider {
        padding: 0.8rem 0.5rem;
        background: none;
        background-color: theme-color("secondary");
        color: theme-color("light-font");
        font-weight: 600;
        font-size: 0.625rem;
        line-height: 1rem;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: theme-color("dark");
      }

      .list-group-item:first-child,
      .list-group-item:last-child {
        border-radius: 0;
      }

      .total-unit {
        font-size: 0.6rem;
      }

      .total-value {
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 2.25rem;
        color: theme-color("background-dark");
      }

      .studio-icon {
        svg {
          margin-right: 0.625rem;
        }
      }

      .instructor {
        width: 3.5rem;
        height: 3.5rem;
        object-fit: cover;
      }

      .instructor-name {
        color: theme-color("dark");
        font-size: 0.6rem;
      }
    }
  }

  .workout-list-box {
    flex: 1;
  }

  .all-workouts {
    p {
      font-weight: 300;
      font-size: 2rem;
      line-height: 3rem;
      color: theme-color("background-dark");
      padding-bottom: 0;
    }
    span {
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: theme-color("dark");
    }
  }

  .summary {
    font-size: 0.8rem;

    .avg-value {
      font-size: 1.2rem;
    }

    .circle {
      font-size: 1.2rem;
      color: #4c2ffe;
      background: rgba(76, 47, 254, 0.15);
      width: 3.5rem;
      height: 3.5rem;
    }

    .cadence {
      .circle {
        color: #4c2ffe;
        background: rgba(76, 47, 254, 0.15);
      }
    }

    .resistance {
      .circle {
        color: #f79039;
        background: rgba(247, 144, 57, 0.15);
      }
    }

    .output {
      .circle {
        color: #ff3347;
        background: rgba(255, 51, 71, 0.15);
      }
    }

    .total {
      .circle {
        color: #1ca091;
        background: rgba(28, 160, 145, 0.15);
      }
    }

    .unit {
      padding-bottom: 2px;
    }
  }

  .chart {
  }

  .top-class-info {
    .class-info-wrapper {
      padding: 1rem;
      background: theme-color("background-darker");
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
      border-radius: 20px;
      .class-info-details {
        &__left {
          figure {
            margin-bottom: 0;
            img {
              border-radius: 10px;
            }
          }
        }
        &__right {
          padding-left: 1.25rem;
          min-height: 100px;
          h3 {
            padding-top: 0.5rem;
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 2.25rem;
            color: theme-color("white");
            text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          }
          .info-btns {
            position: absolute;
            bottom: 0;
            button {
              font-size: 1rem;
            }
          }
        }
      }
      .class-info-btns {
        .play-icon {
          position: relative;
          display: block;
          width: 64px;
          height: 64px;
          border-radius: 100%;
          background-color: theme-color("primary");
          svg {
            position: absolute;
            top: 18px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
          }
        }
        .other-icons {
          position: absolute;
          left: 1rem;
          padding-top: 0.5rem;
          .btn-icon {
            border: 1px solid #4b4b4b;
          }
        }
      }
    }

    .class-stats-wrapper {
      .stats-item {
        padding: 0.8rem 1rem;
        background: theme-color("white");
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
        border-radius: 20px;
        span {
          margin-left: 1rem;
          font-weight: 300;
          font-size: 1.8rem;
          line-height: 2rem;
          color: theme-color("background-dark");
          small {
            font-size: 0.8rem;
          }
        }
        svg {
          margin-bottom: 10px;
        }
      }
    }
    .diagram-wrapper {
      background: theme-color("white");
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
      border-radius: 20px;
      .diagram-content {
        border-radius: 20px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .profile {
    .avatar {
      margin-bottom: 2rem;
      .initials {
        font-size: 2rem;
      }
    }
    .profile-wrapper {
      padding-top: 2rem;
      .edit-btn-wrapper {
        padding-top: 0;
        .btn {
          width: 100%;
        }
      }
      .personal-content {
        bottom: 50px;
      }
    }
  }
  .top-nav-mobile {
    overflow-x: auto;
    white-space: nowrap;
    .navbar {
      ul {
        li {
          display: inline-block;
          float: none;
          a {
            background-color: theme-color("secondary");
            border-radius: 10px;
            color: theme-color("dark");
            margin-right: 1rem;
            padding: 0.5rem;
            display: block;
            text-decoration: none;
            &.selected {
              background-color: theme-color("primary");
              color: theme-color("white");
              svg {
                path {
                  stroke: theme-color("white");
                }
              }
            }
            &:hover {
              background-color: theme-color("primary");
              color: theme-color("white");
              svg {
                path {
                  stroke: theme-color("white");
                }
              }
            }
          }
        }
      }
    }
  }
  .top-account-link {
    .close-icon-wrapper {
      a {
        position: relative;
        display: block;
        width: 64px;
        height: 64px;
        background: #4f4f4f;
        border-radius: 50%;
        padding: 0;
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
