.katana-admin {
  $dark-grey-color: #272a2e;
  --electric-purple: #972ffe;

  .pagination {
    justify-content: space-between;
    display: flex;
    margin: 5px 0 5px 0;
    .total-items {
      padding-left: 20px;
    }

    .select-page {
      width: 60px;
      margin: 0 10px 0 10px;
    }

    .select-order {
      width: 120px;
      margin: 0 10px 0 10px;
    }
    .btn-primary {
      background-color: theme-color('primary');
      color: theme-color('white');
    }
  }

  .katana-svg-icon {
    margin-bottom: 2px;
    margin-right: 4px;
  }

  .video-thumbnail-wrapper {
    overflow: auto;
    white-space: nowrap;

    .video-thumbnail-item,
    .load-more-thumbnail-item {
      display: inline-block;
      color: white;
      height: 200px;
      background: #f4f5fb;
      width: 194px;
      height: 158px;
      margin: 10px 5px;
      border-radius: 5px;
      padding-left: 4px;
      position: relative;
      background-position: center;
      background-size: cover;
    }

    .thumbnail-text-description {
      float: left;
      margin-left: 46px;
      margin-top: 52px;
      font-family: Ubuntu;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 3.11;
      letter-spacing: normal;
      color: #272a2e;
    }

    .video-thumbnail-item.load-more-box:hover {
      border: 1px solid #972ffe;
      color: #972ffe;
    }

    .video-thumbnail-item.load-more-box:hover > p {
      color: #972ffe;
    }

    .video-thumbnail-item i {
      display: none;
      position: absolute;
      top: 5px;
      left: 5px;
    }

    .video-thumbnail-item i.fa-check-circle {
      display: inline;
      color: #972ffe;
    }

    .video-thumbnail-item.selected-thumbnail {
      cursor: default;
    }

    .video-thumbnail-item:hover > i {
      display: inline;
    }

    .video-thumbnail-item:hover {
      cursor: pointer;
    }

    .video-thumbnail-item:first-child {
      margin-left: 0;
    }

    .video-thumbnail-item:last-child {
      margin-right: 0;
    }
  }

  span.status-badge:before {
    display: inline-block;
    content: "";
    height: 12px;
    width: 12px;
    margin-right: 10px;
    -webkit-border-radius: 16px;
    border-radius: 16px;
    font-size: 16px;
  }

  span.active-status-badge {
    color: #2f6bfe;
  }

  span.active-status-badge:before {
    background-color: #2f6bfe;
  }

  span.draft-status-badge {
    color: #818999;
  }

  span.draft-status-badge:before {
    background-color: #818999;
  }

  span.archived-status-badge {
    color: #fe782f;
  }

  span.archived-status-badge:before {
    background-color: #fe782f;
  }

  .primary-edit-button {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .left-side-menu {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.13);
    width: 250px;
    z-index: 10;
    background: #fff;
    bottom: 0;
    position: fixed;
    transition: all 0.2s ease-out;
    top: 0;

    ul {
      list-style-type: none;
      padding: 0;
    }

    .side-nav-title {
      height: 70px;
      background-color: theme-color("primary");

      h5 {
        font-size: 1rem;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
      }

      li {
        list-style: none;
      }
    }

    .side-nav-item {
      .active {
        color: theme-color("dark");
      }

      :hover {
        color: theme-color("dark");
      }

      a {
        cursor: pointer;
        color: theme-color("light-font");
      }

      .title {
        font-weight: 900;
        font-size: 1.2rem;
      }
    }
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    box-shadow: 0 1px 0 0 #f2f2f4;

    .navbar-brand {
      img {
        height: 45px;
      }
    }

    .navbar-container {
      flex: 1;
    }

    .navbar-container-right {
      flex: 1;
      text-align: right;
    }

    span {
      font-size: 1.2rem;
    }

    .d-flex {
      color: theme-color("dark");
    }

    background-color: #fff;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    margin: 0 0 0 250px;
    min-height: 70px;
  }

  .content-page {
    margin: 70px 0 0 250px;
    overflow: hidden;
    padding: 2rem 1.5rem 2.5rem 3rem;
    min-height: 80vh;
  }

  .admin-page {
    .breadcrumb {
      font-size: 0.9rem;

      a {
        color: theme-color("light-font");
      }

      a:hover {
        text-decoration: none;
        color: theme-color("dark");
      }

      span {
        color: theme-color("light-font");
      }
    }

    .btn {
      width: 150px;
      height: 40px;
      color: theme-color("dark");
    }

    .btn-light-border {
      border-right: theme-color("light") 3px solid;
    }

    .btn:hover {
      background-color: theme-color("primary");
      border-color: theme-color("primary");
      color: #fff;
    }

    .card {
      li {
        margin: 0 2rem 0 0;

        a {
          color: theme-color("dark");
        }
      }

      .active {
        margin: 0 2rem -0.8rem 0;
        border-bottom: theme-color("primary") 2px solid;
      }
    }

    .card-body {
      .validation-message {
        color: #c60000;
      }

      label {
        color: theme-color("dark");
        font-size: 1rem;
        font-weight: normal;
      }

      input,
      select {
        height: 50px;
        padding: 0 1.5rem 0 1.5rem;
      }

      select {
        appearance: none;
        background-position: 97% 50%;
        background-repeat: no-repeat;
        background-image: url("../../images/select-ico.svg");

        &:invalid {
          color: theme-color("light-font");
        }
      }

      textarea {
        padding: 1rem 0 0 1.5rem;
      }

      input::placeholder {
        color: theme-color("light-font");
        font-size: 1rem;
        font-weight: lighter;
      }
    }
  }

  .admin-page-header {
    font-family: Ubuntu;
    margin-top: 15px;
    margin-bottom: 60px;

    .admin-page-title {
      height: 45px;
      font-size: 40px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: -0.03px;
      color: $dark-grey-color;
    }

    ul.breadcrumb-nav {
      padding-left: 0;
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      color: #818999;

      .breadcrumb-item {
        color: #818999;
        margin-right: 3px;
      }

      .breadcrumb-item::after {
        content: "/";
        margin-left: 4px;
      }

      .breadcrumb-actve-item {
        padding-left: 4px;
        color: #818999;
        position: relative;
        padding-left: 12px;
      }

      .breadcrumb-actve-item::before {
        content: url("../../images/active-breadcrumb-link-ico.png");
        position: absolute;
        top: -1px;
        left: 1px;
      }
    }

    .admin-page-seperator-line {
      hr {
        margin: 18px 0 0 0;
        border: solid 1px #e8e8e8;
      }
    }

    .main-page-header-toolbar {
      width: auto;
      text-align: left;

      .btn {
        margin-left: 10px;
        width: 150px;
        height: 40px;
        padding: 9px 4px;
      }

      .default-admin-button {
        border-radius: 4px;
        background-color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        text-align: center;
        color: #272a2e;
        border: solid 1px #e8e8e8;
        margin-top: 3px;
      }

      .primary-admin-button {
        border-radius: 4px;
        background-color: theme-color('primary');
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        text-align: center;
        color: theme-color('white');
        margin-top: 3px;
      }
    }
  }

  .admin-page-main-content {
    background: #ffff;

    td.column-30-width {
      width: 30%;
    }

    td.pr-80p {
      padding-left: 80px;
    }

    .table tbody:first-child tr th:first-child,
    .table tbody:first-child tr td:first-child {
      padding-left: 27px;
    }

    .table tbody:first-child tr th:last-child,
    .table tbody:first-child tr td:last-child {
      padding-right: 27px;
    }

    .table tbody tr {
      border: 0;
      border-bottom: 2px solid #f4f5fb;
    }

    .table tbody th {
      font-size: 16px;
    }

    .table tbody td {
      font-size: 14px;
    }

    .table tbody th,
    .table tbody td {
      padding-top: 20px;
      padding-bottom: 20px;
      vertical-align: middle;
      box-shadow: 0 1px 0 0 #e8e8e8;
      border-top: 0;
      border-bottom: 0;
    }
  }
}
