.profile-editor {
  .profile-editor-btn {
    width: 150px;
  }

  @media screen and (max-width: 1200px) {
    .profile-editor-btn {
      width: auto;
    }
  }

  .profile-selected-avatar {
    max-width: 100%;
  }

  .profile-selected-avatar-container {
    max-width: 177px;
    min-width: 50px;
    max-height: 186px;
    min-height: 50px;
    width: 15vw;
    height: 15vw;
    margin-left: 15px;
    margin-right: 15px;
  }

  .profile-avatar-option {
    max-width: 85px;
    min-width: 15px;
    max-height: 88px;
    min-height: 15px;
    width: 7vw;
    height: 7vw;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .selected-avatar-option {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .fa-check-circle {
      font-size: 14px;
      color: #fff;
      padding: 5px;
    }
  }
}
