.achievements-page-wrapper {
  .item-achievement-badge {
    text-align: center;
    .img-achievement-wrapper {
      padding: 10px;
      .achievement-badge-img {
        // width: 80%;
        // max-height: 190px;
        opacity: 1;
        &.achievement-badge-waiting {
          opacity: 0.2;
        }
      }
    }
  }
  h4 {
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2.625rem;
    color: theme-color("background-dark");
  }
}

@media only screen and (max-width: 768px) {
  .achievements-mobile-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    .item-achievement-badge {
      display: inline-block;
      float: none;
      .achievement-badge-img {
        opacity: 1;
        &.achievement-badge-waiting {
          opacity: 0.2;
        }
      }
    }
  }
}
