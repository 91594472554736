.leaderboard-page {
  h2 {
    font-weight: 600;
    font-size: 42px;
    line-height: 63px;
    display: flex;
    align-items: center;
    color: #333333;
  }
  .avatar {
    position: relative;
    width: 60px;
    overflow: hidden;
    svg {
      border: 2px solid #ffffff;
      border-radius: 50%;
    }
    .initials {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      text-transform: uppercase;
    }
  }

  .avatar-name {
    padding-left: 1rem;
    font-weight: 600;
    font-size: 1rem;
    color: #333333;
  }

  .rank-text {
    padding-left: 1rem;
  }

  .rank-col {
    max-width: 100px;
  }

  .leaderboard-content {
    margin-bottom: 80px;
  }

  .leaderboard-tabs {
    background-color: theme-color('text-gray');
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    ul {
      border: none;
      li {
        cursor: pointer;
        a {
          color: theme-color('disabled-font');
          padding: 1.5rem 0.5rem;
          background-color: theme-color('text-gray');
          border: none;
          border-radius: 0;
          text-transform: uppercase;
          &.active {
            color: theme-color('white');
            background-color: theme-color('primary');
          }
        }
      }
    }
  }

  .leaderboard-header {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #828282;
    margin-top: 120px;
  }

  .leaderboard-row-highlighted {
    background-color: theme-color("primary");
    .avatar-name,
    .leaderboard-row-text,
    .leaderboard-row-small {
      color: #ffffff !important;
    }
  }

  .leaderboard-row-text {
    font-weight: 300;
    font-size: 1.8rem;
    color: #333333;
    padding-right: 10px;
  }
  .leaderboard-row-small {
    font-weight: 300;
    color: #333333;
  }
}

tbody {
  tr {
    border-top: 0.7rem solid theme-color("light");
    border-bottom: 0.7rem solid theme-color("light");
  }
}

table {
  td {
    vertical-align: center;
  }
}

.rounded-circle {
  width: 3rem;
  height: 3rem;
}

.red {
  color: red;
}
.green {
  color: green;
}
.blue {
  color: blue;
}

@media only screen and (max-width: 768px) {
  .leaderboard-page {
    .leaderboard-content {
      margin-top: 2rem;
    }
    .leaderboard-content-wrapper {
      padding: 0 1rem;
    }
    .leaderboard-row-item {
      border-radius: 0.875rem !important;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    }
    .leaderboard-avatar-wrapper {
      padding-right: 0 !important;
      .avatar {
        width: 45px;
        .initials {
          left: 45%;
        }
      }
    }
    .leaderboard-title {
      margin-left: 0 !important;
      h2 {
        font-size: 2rem;
        line-height: 2.2rem;
      }
    }
    .leaderboard-header {
      padding-left: 1rem;
      margin-top: 5rem !important;
      font-size: 0.75rem;
      text-align: left !important;
    }
    .leaderboard-tabs {
      ul {
        li {
          a {
            padding: 0.5rem;
          }
        }
      }
    }
    .leaderboard-row-highlighted {
      background: theme-color('white');
      .avatar-name {
        color: theme-color('background-dark')!important;
      }
      .leaderboard-row-text {
        color: theme-color('background-dark')!important;
      }
      .leaderboard-row-small {
        color: theme-color('background-dark')!important;
      }
    }
    .leaderboard-profile-highlighted {
      position: fixed;
      background: theme-color('primary');
      color: theme-color('white');
      bottom: 55px;
      width: 100%;
      left: 0;
      z-index: 10;
      border-radius: 0 !important;
      .avatar {
        width: auto;
      }
      .avatar-name {
        padding-left: .8rem;
        color: theme-color('white');
      }
      .leaderboard-row-text {
        color: theme-color('white');
      }
      .leaderboard-row-small {
        color: theme-color('white');
      }
    }
    .rank-col {
      max-width: 0;
      padding-left: 0;
      padding-right: 1rem;
      margin-right: 1rem;
    }
    .avatar {
      float: left;
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-top: 2px !important;
    }
    .avatar-name {
      font-size: 0.8rem;
      padding-left: 0;
      margin: 0 !important;
    }
    .leaderboard-total-energy {
      margin-left: 0.5rem;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .leaderboard-row-text {
      font-size: 1.125rem;
      width: 85%;
    }
    .leaderboard-row-small {
      width: 15%;
      text-transform: lowercase;
    }
  }
}
